import { idToken } from '~/store/credentials';
import { profile } from '~/store/profile';
import { Asset, Schedule } from '~/utils/metadata';

interface BaseMediaCustomData {
  assetId: string;
  forgerockTokensData?: {
    idToken: string;
  };
  isLive: boolean;
  playSource: string;
  senderName: string;
  senderVersion: string;
  selectedProfile?: { id: string };
  asset?: Asset | Schedule;
  channelId?: string;
  provider?: string;
  vodPlayStartTime?: number;
}

export interface LiveMediaCustomData extends BaseMediaCustomData {
  isLive: true;
  asset?: Schedule;
  schedules?: Schedule[];
}

export interface VodMediaCustomData extends BaseMediaCustomData {
  isLive: false;
  asset?: Asset;
}

export type MediaCustomData = LiveMediaCustomData | VodMediaCustomData;

export type MediaInformation = Omit<
  framework.messages.MediaInformation,
  'customData'
> & {
  customData?: MediaCustomData;
};

export function getLoadRequest(assetId: string, isLive = false) {
  const loadRequest = new cast.framework.messages.LoadRequestData();

  loadRequest.media = new cast.framework.messages.MediaInformation();
  loadRequest.media.customData = {
    isLive,
    assetId,
    selectedProfile: { id: profile()?.id },
    forgerockTokensData: { idToken: idToken() },
  };
  loadRequest.autoplay = true;

  return loadRequest;
}
