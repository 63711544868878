import { sortBy } from 'lodash-es';

export function greatestCommonDivisor(a: number, b: number) {
  let x = a;
  let y = b;

  while (y) {
    const t = y;
    y = x % y;
    x = t;
  }
  return x;
}

export function simplifyFrac(num: number, den: number) {
  const gcd = greatestCommonDivisor(num, den);
  return [num / gcd, den / gcd];
}

export function getAspectRatio(dimensions: { width: number; height: number }) {
  const { width, height } = dimensions;

  const [num, den] = simplifyFrac(width, height);
  return `${num}:${den}`;
}

export function aspectRatioToFrac(aspectRatio: string) {
  const [num, den] = aspectRatio
    .split(':')
    .map((value) => Number.parseInt(value, 10));
  return [num, den];
}

export function getProportion(aspectRatio: string) {
  const [num, den] = aspectRatioToFrac(aspectRatio);
  return num / den;
}

export function getHeightByWidth(width: number, ratio: string) {
  const [num, den] = aspectRatioToFrac(ratio);
  return width * (den / num);
}

export interface Range {
  start: number;
  end: number;
}

export function isBetween(value: number, range: Range) {
  return value >= range.start && value <= range.end;
}

export function areRangesOverlapped(range1: Range, range2: Range) {
  return (
    isBetween(range1.start, range2) ||
    isBetween(range1.end, range2) ||
    isBetween(range2.start, range1) ||
    isBetween(range2.end, range1)
  );
}

export function isRangeEqual(range1: Range, range2: Range) {
  return range1.start === range2.start && range1.end === range2.end;
}

export function endsBeforeStart(range1: Range, range2: Range) {
  return range1.end <= range2.start;
}

export function resolveAfter(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function getHoursMinutesSeconds(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  const remainingSeconds = Math.floor(seconds - hours * 3600 - minutes * 60);
  return [hours, minutes, remainingSeconds];
}

export function formatSecondsInText(seconds: number) {
  const [hours, minutes] = getHoursMinutesSeconds(seconds);
  const formattedHours = hours > 0 ? ` ${hours}h` : '';
  const formattedMinutes = minutes > 0 ? ` ${minutes}m` : '';
  return `${formattedHours}${formattedMinutes}`.trim();
}

export function formatSecondsInTime(seconds: number) {
  const [hours, minutes, remainingSeconds] = getHoursMinutesSeconds(seconds);

  return `${hours}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}

export function isConnected() {
  return navigator.onLine;
}

export function combineLiveSections<T>(
  oldSections: T[],
  newSections: T[],
  getRange: (section: T) => Range,
) {
  // If there are no new sections, return the old sections
  if (newSections.length === 0) {
    return oldSections;
  }

  // Sort the new sections by start time
  const sortedNewSections = sortBy(
    newSections,
    (section) => getRange(section).start,
  );

  // If there are no old sections, return the sorted new sections
  if (oldSections.length === 0) {
    return sortedNewSections;
  }

  const result = []; // The combined sections

  let oldIndex = 0; // Index for the old sections
  let newIndex = 0; // Index for the new sections

  // Iterate over the new sections
  while (newIndex < sortedNewSections.length) {
    const newSchedule = sortedNewSections[newIndex];

    // If there are no more old sections, add the new schedule and move to the next new schedule
    if (oldIndex >= oldSections.length) {
      result.push(newSchedule);
      newIndex += 1;
    } else {
      const oldSchedule = oldSections[oldIndex];
      const oldRange = getRange(oldSchedule);
      const newRange = getRange(newSchedule);

      if (isRangeEqual(oldRange, newRange)) {
        // If the ranges are the same, add the new schedule and move to the next new and old schedules
        result.push(newSchedule);
        oldIndex += 1;
        newIndex += 1;
      } else if (endsBeforeStart(oldRange, newRange)) {
        // If the old ends before the new, we can keep the old schedule
        result.push(oldSchedule);
        oldIndex += 1;
      } else if (endsBeforeStart(newRange, oldRange)) {
        // If the new ends before the old, add the new schedule and move to the next
        result.push(newSchedule);
        newIndex += 1;
      } else if (areRangesOverlapped(oldRange, newRange)) {
        // If the ranges overlap, discard the old schedule
        oldIndex += 1;
      }
    }
  }

  // Add any remaining old schedules
  while (oldIndex < oldSections.length) {
    result.push(oldSections[oldIndex]);
    oldIndex += 1;
  }

  return result;
}
