import { createBackendService, ClientType } from '@dtvgo/backend-service';

import { getUpdatedCredentials } from '~/store/credentials';
import { profileToken } from '~/store/profile/data';
import { CLIENT_HEADERS_OPTIONS } from '~/utils/clientHeadersOptions';
import { LANGUAGE } from '~/utils/constants';
import { errorFactory } from '~/utils/errorFactory';
import { isConnected } from '~/utils/functions';

export const middleware = createBackendService({
  host: import.meta.env.VITE_MIDDLEWARE_HOST,
  getAccessTokenData: getUpdatedCredentials,
  ...CLIENT_HEADERS_OPTIONS,
  profileToken,
  clientType: ClientType.CHROMECAST,
  language: LANGUAGE,
  errorFactory,
  isConnected,
});
