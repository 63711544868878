import { createEffect, createSignal, onCleanup } from 'solid-js';

import { VrioOttError } from '@dtvgo/error';

import { addErrorListener, PlayerState, PlayerStateType } from '~/cast/lib';

import { errorFactory } from '~/utils/errorFactory';

export function createErrorSignal(playerState: () => PlayerStateType) {
  const [error, setError] = createSignal<VrioOttError | undefined>(undefined);
  let cleanErrorTimeout: ReturnType<typeof setTimeout> | undefined;

  const handleError = (castError: unknown) => {
    setError(errorFactory.ensureVrioOttError(castError));
    // If we don't enter to IDLE state in 10 seconds, we clean the error
    // This is to avoid to display the same error if it didn't lead to a stop
    cleanErrorTimeout = setTimeout(() => {
      setError(undefined);
      cleanErrorTimeout = undefined;
    }, 10_000);
  };

  // Add error listener
  createEffect(() => {
    const cleanup = addErrorListener(handleError);
    onCleanup(cleanup);
  });

  // Cancel clear error tiemout if we enter in IDLE state
  createEffect(() => {
    if (playerState() === PlayerState.IDLE) {
      clearTimeout(cleanErrorTimeout);
      cleanErrorTimeout = undefined;
    }
  });

  // Clear error when we start to load a new content
  createEffect(() => {
    if (playerState() === PlayerState.LOADING) setError(undefined);
  });

  onCleanup(() => {
    if (cleanErrorTimeout) {
      clearTimeout(cleanErrorTimeout);
      cleanErrorTimeout = undefined;
    }
  });

  return [error, handleError] as [typeof error, typeof handleError];
}
