import { datadogRum } from '@datadog/browser-rum';
import {
  DD_SAMPLE_RATE,
  DD_REPLAY_SAMPLE_RATE,
  IS_DATADOG_ACTIVE,
} from './env';

if (IS_DATADOG_ACTIVE) {
  datadogRum.init({
    applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: import.meta.env.VITE_DATADOG_SERVICE,
    sessionSampleRate: DD_SAMPLE_RATE,
    sessionReplaySampleRate: DD_REPLAY_SAMPLE_RATE,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    env: import.meta.env.VITE_ENVIRONMENT || 'dev',
    version: import.meta.env.VITE_APP_VERSION,
    allowedTracingUrls: [
      import.meta.env.VITE_MIDDLEWARE_HOST,
      import.meta.env.VITE_TOOLBOX_HOST,
    ],
  });
}
