import { createEffect, untrack } from 'solid-js';

import { TimeCounter } from '@dtvgo/utils';

import { PlayerState, PlayerStateType } from '~/cast/lib';

import { createAssetChangeEffect } from './createAssetChangeEffect';

export function createGetStreamedTime({
  assetId,
  playerState,
}: {
  assetId: () => string | undefined;
  playerState: () => PlayerStateType;
}) {
  const streamedTimeCounter = new TimeCounter();

  createAssetChangeEffect(assetId, () => {
    // Reset the streamed time counter
    streamedTimeCounter.reset();
    if (untrack(playerState) === PlayerState.PLAYING) {
      // Start the streamed time counter if we are in playing state
      streamedTimeCounter.start();
    }
  });

  // Streamed time counter actions on player state change
  createEffect(() => {
    switch (playerState()) {
      case PlayerState.PLAYING: {
        streamedTimeCounter.start();
        break;
      }
      case PlayerState.PAUSED:
      case PlayerState.BUFFERING: {
        streamedTimeCounter.stop();
        break;
      }
      case PlayerState.IDLE:
      case PlayerState.LOADING: {
        streamedTimeCounter.reset();
        break;
      }
      default: {
        break;
      }
    }
  });

  return () => streamedTimeCounter.getElapsedSeconds();
}
