import NpawPlugin from 'npaw-plugin';

import { VrioOttError, VrioOttErrorFactory } from '@dtvgo/error';
import {
  getMSOProviderFormatted,
  getUserTypeByBusinessUnit,
} from '@dtvgo/utils';
import { ContentType } from '@dtvgo/backend-service';

import { Asset } from '../metadata';
import { decodeToolboxToken } from '../toolbox';
import {
  AppInfo,
  ConfigureAdaptersOptions,
  DeviceInfo,
  getErrorParams,
  getValueOrEmpty,
  getValueOrNotApplicable,
  getValueOrNotAvailable,
  prependIfValue,
} from './utils';

class VrioOttNpawIntegration {
  private plugin?: NpawPlugin;

  private isLiveContent: boolean | undefined;

  private channelName: string | undefined;

  private asset: Asset | undefined;

  private isConnected?: () => boolean;

  public init(
    npawAccountCode: string,
    configureAdapters: (
      plugin: NpawPlugin,
      options: ConfigureAdaptersOptions,
    ) => void,
    appInfo: AppInfo,
    options: {
      isConnected: () => boolean;
      errorFactory: VrioOttErrorFactory;
    },
  ) {
    if (this.plugin) return this;

    this.plugin = new NpawPlugin(npawAccountCode);
    this.plugin.setAnalyticsOptions({
      'app.name': appInfo.name,
      'app.releaseVersion': `${appInfo.name} - ${appInfo.version}`,
    });

    const { errorFactory, isConnected } = options;
    this.isConnected = isConnected;

    configureAdapters(this.plugin, {
      errorFormatter: (error) =>
        getErrorParams(errorFactory.ensureVrioOttError(error), { isConnected }),
    });

    return this;
  }

  private updateContentTitle() {
    let titleParts;
    if (this.isLiveContent === true && this.channelName) {
      titleParts = ['LIVE', this.channelName];
    } else if (this.isLiveContent === false && this.asset) {
      titleParts =
        this.asset.type === ContentType.EPISODE ?
          [
            'VOD',
            this.asset.episode?.showName,
            prependIfValue('S', this.asset.episode?.seasonNumber),
            prependIfValue('E', this.asset.episode?.number),
            this.asset.title,
          ]
        : ['VOD', this.asset.title];
    }

    if (titleParts) {
      this.plugin?.setVideoOptions({
        'content.title': titleParts.filter(Boolean).join('-'),
      });
    }

    return this;
  }

  public setDeviceInfo(deviceInfo: DeviceInfo) {
    this.plugin?.setAnalyticsOptions({
      'device.model': deviceInfo?.model,
      'device.osVersion': deviceInfo?.osVersion,
      'content.customDimension.8': deviceInfo?.firmwareVersion,
    });

    return this;
  }

  public setToolboxToken(toolboxToken: string) {
    const {
      vrioCustomerId,
      businessUnit,
      msoProvider,
      selectedSkySubscription,
    } = decodeToolboxToken(toolboxToken);

    const userType = getUserTypeByBusinessUnit(businessUnit);
    const msoProviderFormatted = getMSOProviderFormatted({
      msoProvider,
      businessUnit,
    });

    this.plugin?.setAnalyticsOptions({
      'user.type': vrioCustomerId ? 'Registered' : 'Unregistered',
      'user.name': vrioCustomerId,
      'content.customDimension.3': userType.toUpperCase(),
      'content.customDimension.11': getValueOrEmpty(msoProviderFormatted),
      'content.customDimension.14': getValueOrNotAvailable(
        selectedSkySubscription,
      ),
    });

    return this;
  }

  public setChromecastSender(chromecastSender: string) {
    this.plugin?.setAnalyticsOptions({
      'content.customDimension.7': chromecastSender, // chromecastSender - Only for Chromecast. Example: "DTVGo iOS - 4.0.0"
    });

    return this;
  }

  public setChannelId(channelId: string) {
    this.plugin?.setVideoOptions({
      'content.customDimension.4': getValueOrEmpty(channelId), // channelId
    });

    return this;
  }

  public setProviderId(providerId: string) {
    this.plugin?.setVideoOptions({
      'content.customDimension.5': getValueOrEmpty(providerId), // providerId
    });

    return this;
  }

  public setIsLiveContent(isLive: boolean) {
    this.isLiveContent = isLive;

    this.updateContentTitle();

    this.plugin?.setVideoOptions({
      'content.isLive': isLive,
      'content.playbackType': isLive ? 'LIVE' : 'VOD',
    });

    return this;
  }

  public setChannelName(channelName: string) {
    this.channelName = channelName;
    this.updateContentTitle();
    this.plugin?.setVideoOptions({
      'content.channel': getValueOrEmpty(channelName),
    });

    return this;
  }

  public setAsset(asset: Asset) {
    this.asset = asset;
    this.updateContentTitle();

    this.plugin?.setVideoOptions({
      'content.program': asset.episode?.showName || asset.title,
      'content.id': getValueOrEmpty(asset.vrioAssetId),
      'content.season': getValueOrEmpty(asset.episode?.seasonNumber),
      'content.episodeTitle': getValueOrNotApplicable(
        asset.type === ContentType.EPISODE ? asset.title : undefined,
      ),
      'content.genre': getValueOrEmpty(asset.genres?.[0]),
      'content.type': asset.type,
      'content.tvShow': getValueOrNotApplicable(asset.episode?.showName),
      'content.saga': getValueOrEmpty(asset.episode?.showName),
      'content.gracenoteId': getValueOrEmpty(asset.metadata?.id),
      'content.customDimension.5': getValueOrNotApplicable(
        asset.episode?.number,
      ), // episodeNumber
      'content.customDimension.6': getValueOrEmpty(asset.genres?.join(', ')), // genreList - This is the full list separated by comma.
      'content.customDimension.9': getValueOrEmpty(asset.rating), // rating - Example: "L", "18"
    });

    return this;
  }

  public setVodPlayStartTime(vodPlayStartTime: number) {
    this.plugin?.setVideoOptions({
      'content.customDimension.10': vodPlayStartTime,
    });

    return this;
  }

  public setPlaySource(playSource: string) {
    this.plugin?.setVideoOptions({
      'content.customDimension.1': getValueOrEmpty(playSource), // playSource - Where the play started, examples: "LiveLander-EPG"/ "HomeLander-FeaturedCarousel"
    });

    return this;
  }

  public reportError(
    error: VrioOttError,
    isFatal: boolean = false,
    source?: string,
  ) {
    const { name, description, metadata } = getErrorParams(error, {
      isConnected: this.isConnected,
    });
    this.plugin
      ?.getAdapter()
      .fireError(
        name,
        description,
        metadata,
        isFatal ? 'fatal' : 'error',
        source,
        isFatal,
      );
  }
}

export const integration = new VrioOttNpawIntegration();
