import { Component, For, JSX } from 'solid-js';

interface HorizontalListProps {
  class?: string;
  items: JSX.Element[];
  separator?: string;
}

const HorizontalList: Component<HorizontalListProps> = (props) => (
  <ul class={`m-0 flex ${props.class || ''}`}>
    <For each={props.items}>
      {(item) => (
        <li
          class={`before:content-['•'] before:mx-1 first:before:content-none`}
        >
          {item}
        </li>
      )}
    </For>
  </ul>
);

export default HorizontalList;
