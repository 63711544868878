import { createMemo } from 'solid-js';
import { createQuery, QueryClient, queryOptions } from '@tanstack/solid-query';

import { middleware } from '~/services/middleware';

import { getAppConfig } from '~/utils/appConfig';

export const queryClient = new QueryClient();

export function appConfigQuery() {
  return queryOptions({
    queryKey: ['appConfig'] as ['appConfig'],
    queryFn: getAppConfig,
  });
}

export function channelsQuery() {
  return queryOptions({
    queryKey: ['channels'] as ['channels'],
    queryFn: async () => {
      const {
        data: { channels },
      } = await middleware.content.v2.getChannels(false);
      return channels;
    },
    staleTime: Number.POSITIVE_INFINITY,
    gcTime: Number.POSITIVE_INFINITY,
  });
}

export function useChannel(channelId: () => string | undefined) {
  const channels = createQuery(() => channelsQuery());

  // eslint-disable-next-line sonarjs/prefer-immediate-return -- avoid error with solid reactivity
  const channel = createMemo(() =>
    channelId() === undefined ? undefined : (
      channels.data?.find(({ channelId: id }) => id === channelId())
    ),
  );

  return {
    channel,
    isLoading: () => channels.isLoading,
  };
}

export function assetQuery(assetId: string) {
  const queryKey = ['asset', assetId] as ['asset', typeof assetId];
  return queryOptions({
    queryKey,
    queryFn: async ({ queryKey: [, assetIdFromQueryKey] }) => {
      const { data } =
        await middleware.content.v1.getAssetDetail(assetIdFromQueryKey);
      return data;
    },
    staleTime: Number.POSITIVE_INFINITY,
  });
}

export function seasonQuery(seasonId: {
  showId: string;
  seasonNumber: number;
}) {
  return queryOptions({
    queryKey: ['season', seasonId] as ['season', typeof seasonId],
    queryFn: async ({ queryKey: [, { showId, seasonNumber }] }) => {
      const { data } = await middleware.content.v1.getSeasonDetail(
        showId,
        seasonNumber,
      );
      return data;
    },
    staleTime: Number.POSITIVE_INFINITY,
    gcTime: 1.5 * 60 * 60 * 1000, // 1.5 hours
  });
}
