/* @refresh reload */
import '~/utils/datadog';

import { render } from 'solid-js/web';
import { QueryClientProvider } from '@tanstack/solid-query';

import './index.css';

import { App } from './app';
import { queryClient } from './store/tanstack-query';

const root = document.getElementById('root');

render(
  () => (
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  ),
  root!,
);
