import { minBy } from 'lodash-es';
import { ContentType } from '@dtvgo/backend-service';
import { createImageResizer } from '@dtvgo/image-resizers';

import { middleware } from '~/services/middleware';

import { profile } from '~/store/profile';
import { decodedIdToken } from '~/store/credentials';
import { queryClient, seasonQuery } from '~/store/tanstack-query';
import {
  getAspectRatio,
  getHeightByWidth,
  getProportion,
  isBetween,
  resolveAfter,
} from './functions';
import { IS_BRAZIL } from './constants';
import { errorFactory } from './errorFactory';

export interface Image {
  ratio?: string;
  height: number;
  url: string;
  width: number;
}

export interface Asset {
  vrioAssetId: string;
  vmsId?: string | null;
  title?: string | null;
  type: ContentType;
  images?: Image[] | null;
  rating?: string | null;
  genres?: string[] | null;
  vod?: {
    releaseYear?: number | null;
    duration?: number | null;
  } | null;
  episode?: {
    number?: number | null;
    seasonNumber?: number | null;
    showName?: string | null;
    showId?: string | null;
  } | null;
  duration?: number | null;
  metadata?: {
    id: string | null;
  } | null;
}

export interface Schedule extends Asset {
  live?: {
    startTime: string;
    endTime: string;
    blackout: boolean;
    channelId: string;
    channelName: string;
  } | null;
  guideId: string;
}

export const imageResizer = createImageResizer();

export function getImage(images: Image[], ratio: string) {
  return minBy(images, (image) => {
    const imageRatio = image.ratio || getAspectRatio(image);
    let imageProportion = getProportion(imageRatio);
    let targetProportion = getProportion(ratio);
    if (targetProportion > 1) {
      imageProportion = 1 / imageProportion;
      targetProportion = 1 / targetProportion;
    }

    return Math.abs(imageProportion - targetProportion);
  });
}

export function resizeImage(image: Image, width: number) {
  const ratio = image.ratio || getAspectRatio(image);
  const height = getHeightByWidth(width, ratio);

  const url = imageResizer.getImageUrl(image.url, { width, height });
  return { url, width, height };
}

export function findScheduleAtTime<T extends Schedule>(
  schedules: T[],
  time: number,
) {
  return schedules.find(
    (schedule) =>
      schedule.live &&
      isBetween(time * 1000, {
        start: new Date(schedule.live.startTime).getTime(),
        end: new Date(schedule.live.endTime).getTime(),
      }),
  );
}

export function getHeartbeatContent(asset: Asset | Schedule) {
  return {
    asset: {
      vrioAssetId: asset.vrioAssetId,
      vmsId: asset.vmsId || '',
      duration:
        asset.vod?.duration || ('duration' in asset && asset.duration) || 0,
    },
    schedule:
      'live' in asset && asset.live ?
        {
          guideId: ('guideId' in asset && asset.guideId) || '',
          startTime: new Date(asset.live.startTime),
          endTime: new Date(asset.live.endTime),
        }
      : undefined,
  };
}

export function ratingToNumber(rating: string) {
  if (IS_BRAZIL) {
    // When a profile is created with rating "L", the value stored in profile is "0"
    if (rating === '0') return 0;

    return ['L', '10', '12', '14', '16', '18'].indexOf(
      rating.charAt(0) === 'A' ? rating.slice(1) : rating,
    );
  }

  // TODO: add classification for other countries
  return 0;
}

export function isAllowedByParentalControl(rating: string | null | undefined) {
  const currentProfile = profile();
  if (!currentProfile || !currentProfile.ageRating) return true;

  if (rating === null || rating === undefined)
    return currentProfile.isNotRatedContentAllowed ?? true;

  return ratingToNumber(rating) <= ratingToNumber(currentProfile.ageRating);
}

export function checkBlackout(asset: Schedule) {
  if (asset.live?.blackout) {
    throw errorFactory.createBlackoutError({
      userRegions: decodedIdToken()?.regions,
      schedule: {
        channelId: asset.live.channelId,
        channelName: asset.live.channelName,
        vrioAssetId: asset.vrioAssetId,
        startTime: asset.live.startTime,
        endTime: asset.live.endTime,
      },
    });
  }
}

export function checkParentalControl(asset: Asset) {
  if (!isAllowedByParentalControl(asset.rating)) {
    const { profileToken, ...redactedProfile } = profile()!;
    throw errorFactory.createParentalControlError({
      profile: redactedProfile,
      content: {
        ...asset,
        rating: asset.rating!,
      },
    });
  }
}

export async function getChannelSchedulesWithRetries(
  channelId: string,
  delay: number,
  retries = 3,
) {
  let channel;

  try {
    ({
      data: {
        channels: [channel],
      },
    } = await middleware.content.v2.getSchedulesByEventType(channelId, {
      assetToken: false,
      eventType: 'now-next',
      pageSize: 2,
    }));
  } catch (error) {
    console.error(
      '[utils>metadata>getSchedulesWithRetries] get schedules by event type failed',
      error,
    );
  }

  if (!channel?.schedules) {
    console.error(
      `[utils>metadata>getSchedulesWithRetries] no schedules found, pending retries: ${retries}`,
    );
    if (retries > 0) {
      await resolveAfter(delay * 1000);
      return getChannelSchedulesWithRetries(channelId, delay, retries - 1);
    }
    // TODO: Use VrioOttError
    throw new Error('Attempts exhausted');
  }

  return {
    channelId: channel.channelId,
    channelName: channel.channelName || undefined,
    schedules: channel.schedules,
  };
}

function findNextEpisodeIdInSeason(
  currentEpisodeNumber: number,
  seasonEpisodes: Asset[],
) {
  return seasonEpisodes.find(
    (episode) =>
      episode.episode?.number &&
      episode.episode.number === currentEpisodeNumber + 1,
  )?.vrioAssetId;
}

function findFirstEpisodeIdInSeason(seasonEpisodes: Asset[]) {
  let firstEpisode = seasonEpisodes[0];

  if (firstEpisode?.episode?.number) {
    for (const episode of seasonEpisodes) {
      if (!episode.episode?.number) continue;

      if (episode.episode.number < firstEpisode.episode!.number) {
        firstEpisode = episode;
      }
    }
  }

  return firstEpisode?.vrioAssetId;
}

function isLastEpisodeInSeason(episodeNumber: number, seasonEpisodes: Asset[]) {
  return seasonEpisodes.every(
    (episode) =>
      episode.episode?.number && episode.episode.number < episodeNumber,
  );
}

export async function findNextEpisodeId(currentEpisodeInfo: {
  showId: string;
  seasonNumber: number;
  episodeNumber: number;
}) {
  try {
    const currentSeason = await queryClient.fetchQuery(
      seasonQuery({
        showId: currentEpisodeInfo.showId,
        seasonNumber: currentEpisodeInfo.seasonNumber,
      }),
    );

    if (!currentSeason.episodes?.[0]) return undefined;

    const nextEpisodeIdInSeason = findNextEpisodeIdInSeason(
      currentEpisodeInfo.episodeNumber,
      currentSeason.episodes,
    );
    if (nextEpisodeIdInSeason) {
      return nextEpisodeIdInSeason;
    }

    if (
      isLastEpisodeInSeason(
        currentEpisodeInfo.episodeNumber,
        currentSeason.episodes,
      )
    ) {
      // We need to play the first episode in the next season
      const nextSeason = await queryClient.fetchQuery(
        seasonQuery({
          showId: currentEpisodeInfo.showId,
          seasonNumber: currentEpisodeInfo.seasonNumber + 1,
        }),
      );

      if (!nextSeason.episodes?.[0]) return undefined;

      return findFirstEpisodeIdInSeason(nextSeason.episodes);
    }
  } catch (error) {
    console.error(
      '[utils>metadata>findNextEpisodeId] unable to find next episode',
      error,
    );
  }
  return undefined;
}

export function isEpisodeAndHasInfo(asset: Asset): asset is Asset & {
  type: ContentType.EPISODE;
  episode: { showId: string; seasonNumber: number; number: number };
} {
  return (
    asset.type === ContentType.EPISODE &&
    /* eslint-disable eqeqeq -- With `something != undefined` we check both null and undefined */
    asset.episode?.showId != undefined &&
    asset.episode?.seasonNumber != undefined &&
    asset.episode?.number != undefined
    /* eslint-enable eqeqeq -- Re-enable */
  );
}
