import { Component } from 'solid-js';
import './index.css';

interface LoadingBarProps {
  animated?: boolean;
}

const LoadingBar: Component<LoadingBarProps> = (props) => (
  <div class="max-w-xs">
    <span class="block rounded-full bg-neutral-bg-cross-gray overflow-hidden">
      <span
        class="block h-1 rounded-full"
        classList={{
          'animate-width bg-main-brand': props.animated,
        }}
      />
    </span>
  </div>
);

export default LoadingBar;
