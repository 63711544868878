import { Component } from 'solid-js';

import { IS_BRAZIL } from '~/utils/constants';

interface RatingProps {
  rating: string;
}

const Rating: Component<RatingProps> = (props) => {
  const ratingColors = () =>
    IS_BRAZIL ?
      {
        L: 'bg-[#00a651] text-[#ffffff]',
        10: 'bg-[#0095da] text-[#ffffff]',
        12: 'bg-[#fbc115] text-[#101010]',
        14: 'bg-[#f58220] text-[#ffffff]',
        16: 'bg-[#ec1d25] text-[#ffffff]',
        18: 'bg-[#000000] text-[#ffffff]',
        ER: 'bg-[#ffffff] text-[#000000]',
      }[props.rating.charAt(0) === 'A' ? props.rating.slice(1) : props.rating]
    : undefined;

  return (
    <div
      class="border-2 border-solid rounded px-2"
      classList={{
        [`border-neutral-text-primary/70 ${ratingColors()}`]: IS_BRAZIL,
        [`border-neutral-text-secondary text-neutral-text-secondary`]:
          !IS_BRAZIL,
      }}
    >
      {props.rating}
    </div>
  );
};

export default Rating;
