import { Location, Platform, VrioOttErrorFactory } from '@dtvgo/error';

import { isConnected } from './functions';

let currentLocation = Location.SPLASH_SCREEN;
export function setLocation(location: Location) {
  currentLocation = location;
}

export const errorFactory = new VrioOttErrorFactory({
  platform: Platform.CHROMECAST,
  getLocation: () => currentLocation,
  isConnected,
});
