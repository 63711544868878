import { AccessTokenResponse } from '@dtvgo/toolbox-service';
import { ForgerockAccountType, jwtDecode } from '@dtvgo/utils';

export interface ToolboxToken {
  vrioCustomerId: string;
  vrioDeviceId: string;
  name: string;
  givenName: string;
  familyName: string;
  email: string;
  iso2Code: string;
  channels: string[];
  regions: string[];
  csm: string;
  businessUnit: ForgerockAccountType;
  msoProvider: string;
  selectedSkySubscription?: string;
  createdAt: string;
  at_hash: string;
  aud: string;
  azp: string;
  sub: string;
  iat: number;
  exp: number;
  iss: string;
  auth_time: number;
}

export function decodeToolboxToken(toolboxIdToken: string) {
  return jwtDecode<ToolboxToken>(toolboxIdToken);
}

export function createCredentialsFromToolboxResponse(
  toolboxResponse: AccessTokenResponse,
) {
  return {
    accessToken: toolboxResponse.access_token,
    refreshToken: toolboxResponse.refresh_token,
    idToken: toolboxResponse.id_token,
    expiresIn: toolboxResponse.expires_in,
    timestamp: Date.now(),
  };
}
