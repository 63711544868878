import ConnectionLayout from '~/components/ConnectionLayout';
import LoadingBar from '~/components/LoadingBar';

import { MESSAGES } from '~/utils/messages';

const Idle = () => (
  <ConnectionLayout>
    <h1 class="text-heading-2xl">{MESSAGES.READY_TO_CAST_TITLE}</h1>
    <h2 class="text-body-l">{MESSAGES.SELECT_CONTENT_ON_DEVICE}</h2>
    <div class="flex flex-col gap-6 mt-auto">
      <p class="text-heading-2xs">{MESSAGES.SELECT_CONTENT_TO_STREAM}</p>
      <LoadingBar />
    </div>
  </ConnectionLayout>
);

export default Idle;
