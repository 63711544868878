import { createCredentialsRenew } from '@dtvgo/credentials-renew';
import { isTokenExpired } from '@dtvgo/utils';

import { toolbox } from '~/services/toolbox';
import {
  createCredentialsFromToolboxResponse,
  decodeToolboxToken,
} from '~/utils/toolbox';

import {
  businessUnit,
  credentials,
  decodedIdToken,
  msoProvider,
  setCredentials,
  userId,
} from './data';

export const getUpdatedCredentials = createCredentialsRenew(
  async (oldRefreshToken) => {
    const { data } = await toolbox.v2.refreshToken(oldRefreshToken, {
      bussinessUnit: businessUnit()!,
      msoProvider: msoProvider(),
    });

    return createCredentialsFromToolboxResponse(data);
  },
  {
    getCredentials: credentials,
    setCredentials,
  },
);

export async function login(idTokenFromSender: string) {
  const cred = credentials();

  if (
    cred &&
    decodeToolboxToken(idTokenFromSender).vrioCustomerId === userId() &&
    !isTokenExpired(cred.refreshToken) &&
    !isTokenExpired(cred.idToken) &&
    decodedIdToken()?.channels.length
  ) {
    return;
  }

  const {
    data: { user_code: userCode, device_code: deviceCode },
  } = await toolbox.v2.tv.getDeviceCode();
  await toolbox.v2.tv.activateDevice(userCode, idTokenFromSender);
  const { data } = await toolbox.v2.tv.loginDevice(deviceCode);
  setCredentials(createCredentialsFromToolboxResponse(data));
}
