import { noop } from 'lodash-es';
import {
  BufferConfig,
  KeySystem,
  PlayerDrmConfig,
  PlayerRequestInterceptor,
  PlayerRequestType,
  PlayerResponseInterceptor,
  composePlayerRequestInterceptors,
} from '@dtvgo/player-common-utils';

import {
  MediaInformation,
  onRequestProxy,
  onResponseReceivedProxy,
} from './utils';

type ErrorData = framework.messages.ErrorData;
type LoadRequestData<CustomDataType> = Omit<
  framework.messages.LoadRequestData,
  'media'
> & {
  media: MediaInformation<CustomDataType>;
};
interface PlaybackInfo<CustomDataType> {
  contentId: string;
  contentUrl: string;
  streamType: framework.messages.StreamType;
  drmConfig?: PlayerDrmConfig;
  bufferConfig?: BufferConfig;
  onRequest?: PlayerRequestInterceptor;
  onResponseReceived?: PlayerResponseInterceptor;
  onFinished?: () => void;
  customData?: Partial<CustomDataType>;
}

export function addLoadInterceptor<CustomDataType>(
  getPlaybackInfo: (
    request: LoadRequestData<CustomDataType>,
  ) => Promise<PlaybackInfo<CustomDataType> | ErrorData>,
) {
  let onFinishedFn: () => void = noop;

  const playerManager =
    cast.framework.CastReceiverContext.getInstance().getPlayerManager();

  let previousMediaFinishedDeferred:
    | ReturnType<typeof createDeferredPromise<void>>
    | undefined;

  playerManager.setMessageInterceptor(
    cast.framework.messages.MessageType.LOAD,
    async (request) => {
      if (previousMediaFinishedDeferred) {
        // We need to wait that the previous media reported to be finished.
        await previousMediaFinishedDeferred.promise;
        previousMediaFinishedDeferred = undefined;
      }
      const playbackInfo = await getPlaybackInfo(request);

      if (playbackInfo instanceof cast.framework.messages.ErrorData) {
        return playbackInfo;
      }

      const {
        contentId,
        contentUrl,
        streamType,
        drmConfig,
        bufferConfig,
        onRequest,
        onResponseReceived,
        onFinished,
        customData,
      } = playbackInfo;

      request.media.contentId = contentId;
      request.media.contentUrl = contentUrl;
      request.media.streamType = streamType;
      if (customData) {
        request.media.customData = {
          ...(request.media.customData as CustomDataType),
          ...customData,
        };
      }

      onFinishedFn = onFinished || noop;

      updatePlaybackConfig({
        drmConfig,
        bufferConfig,
        onRequest,
        onResponseReceived,
      });

      // This request is the previous media for the next one
      previousMediaFinishedDeferred = createDeferredPromise();
      return request;
    },
  );

  const handleMediaFinished = () => {
    if (previousMediaFinishedDeferred) {
      previousMediaFinishedDeferred.resolve();
    }
    restorePlaybackConfig();
    onFinishedFn();
    onFinishedFn = noop;
  };

  playerManager.addEventListener(
    cast.framework.events.EventType.MEDIA_FINISHED,
    handleMediaFinished,
  );

  return () => {
    playerManager.removeEventListener(
      cast.framework.events.EventType.MEDIA_FINISHED,
      handleMediaFinished,
    );
  };
}

function updatePlaybackConfig({
  drmConfig,
  bufferConfig,
  onRequest,
  onResponseReceived,
}: {
  drmConfig?: PlayerDrmConfig;
  bufferConfig?: BufferConfig;
  onRequest?: PlayerRequestInterceptor;
  onResponseReceived?: PlayerResponseInterceptor;
}) {
  const playerManager =
    cast.framework.CastReceiverContext.getInstance().getPlayerManager();
  const playbackConfig = playerManager.getPlaybackConfig()!;

  playbackConfig.shakaConfig = bufferConfig && {
    streaming: {
      bufferingGoal: bufferConfig.forward,
      bufferBehind: bufferConfig.backward,
    },
  };

  const KeySystemToProtectionSystemMap = {
    [KeySystem.PLAYREADY]: cast.framework.ContentProtection.PLAYREADY,
    [KeySystem.WIDEVINE]: cast.framework.ContentProtection.WIDEVINE,
    [KeySystem.FAIRPLAY]: cast.framework.ContentProtection.NONE,
  };

  playbackConfig.protectionSystem =
    (drmConfig && KeySystemToProtectionSystemMap[drmConfig?.keySystem]) ||
    cast.framework.ContentProtection.NONE;

  playbackConfig.manifestRequestHandler =
    /* eslint-disable-next-line @typescript-eslint/no-misused-promises
        -- async functions are allowed when using Shaka Player
            https://developers.google.com/cast/docs/reference/web_receiver/cast.framework.PlaybackConfig#manifestRequestHandler */
    onRequest && onRequestProxy(PlayerRequestType.MANIFEST, onRequest, 'get');

  /* On new CAF versions manifestHandler is invoked with a second parameter (an object with headers)
    https://developers.google.com/cast/docs/reference/web_receiver/cast.framework.PlaybackConfig#manifestHandler */
  // @ts-expect-error -- Types are not updated in @types/chromecast-caf-receiver
  playbackConfig.manifestHandler =
    onResponseReceived &&
    onResponseReceivedProxy(PlayerRequestType.MANIFEST, onResponseReceived);

  playbackConfig.segmentRequestHandler =
    /* eslint-disable-next-line @typescript-eslint/no-misused-promises
        -- async functions are allowed when using Shaka Player
            https://developers.google.com/cast/docs/reference/web_receiver/cast.framework.PlaybackConfig#segmentRequestHandler */
    onRequest && onRequestProxy(PlayerRequestType.SEGMENT, onRequest, 'get');

  /* On new CAF versions segmentHandler is invoked with a second parameter (an object with headers)
      https://developers.google.com/cast/docs/reference/web_receiver/cast.framework.PlaybackConfig#segmentHandler */
  // @ts-expect-error -- Types are not updated in @types/chromecast-caf-receiver
  playbackConfig.segmentHandler =
    onResponseReceived &&
    onResponseReceivedProxy(PlayerRequestType.SEGMENT, onResponseReceived);

  const { defaultLicenseUrl, getLicenseHeaders } = drmConfig || {};
  playbackConfig.licenseUrl = defaultLicenseUrl;

  /* eslint-disable-next-line @typescript-eslint/no-misused-promises
      -- async functions are allowed when using Shaka Player
          https://developers.google.com/cast/docs/reference/web_receiver/cast.framework.PlaybackConfig#licenseRequestHandler */
  playbackConfig.licenseRequestHandler = onRequestProxy(
    PlayerRequestType.LICENSE,
    composePlayerRequestInterceptors(
      onRequest,
      async (requestType, request) => {
        if (requestType === PlayerRequestType.LICENSE) {
          const headers = await getLicenseHeaders?.();
          return headers ? { headers } : request;
        }

        return request;
      },
    ),
    'post',
  );

  /* On new CAF versions licenseHandler is invoked with a second parameter (an object with headers)
    https://developers.google.com/cast/docs/reference/web_receiver/cast.framework.PlaybackConfig#licenseHandler */
  // @ts-expect-error -- Types are not updated in @types/chromecast-caf-receiver
  playbackConfig.licenseHandler =
    onResponseReceived &&
    onResponseReceivedProxy(PlayerRequestType.LICENSE, onResponseReceived);
}

function restorePlaybackConfig() {
  const playerManager =
    cast.framework.CastReceiverContext.getInstance().getPlayerManager();
  const playbackConfig = playerManager.getPlaybackConfig()!;
  playbackConfig.protectionSystem = cast.framework.ContentProtection.NONE;
  playbackConfig.manifestRequestHandler = undefined;
  playbackConfig.manifestHandler = undefined;
  playbackConfig.segmentRequestHandler = undefined;
  playbackConfig.segmentHandler = undefined;
  playbackConfig.licenseUrl = undefined;
  playbackConfig.licenseRequestHandler = undefined;
  playbackConfig.shakaConfig = undefined;
}

interface Deferred<T> {
  promise: Promise<T>;
  resolve: (value: T | PromiseLike<T>) => void;
  reject: (reason?: unknown) => void;
}

export function createDeferredPromise<T>() {
  const deferred: Partial<Deferred<T>> = {};

  deferred.promise = new Promise<T>((resolve, reject) => {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });
  return deferred as Deferred<T>;
}
