import { createEffect } from 'solid-js';

import { initCastReceiver, MessageType } from '~/cast/lib';
import { NAMESPACE } from '~/utils/constants';

export function createInitCastReceiverEffect(
  getConfig: () => { shakaVersion?: string } | undefined,
  events: { onShutdown: () => void },
) {
  let alreadyInitialized = false;

  // Init cast once we have the app config
  createEffect(() => {
    const config = getConfig();
    if (!config) return;

    if (import.meta.hot && import.meta.env.MODE === 'development') {
      const hmrData = import.meta.hot.data as Record<string, unknown>;

      if (hmrData.alreadyInitialized) return;
      hmrData.alreadyInitialized = true;
    }

    if (alreadyInitialized) return;

    alreadyInitialized = true;
    initCastReceiver({
      shakaVersion: config.shakaVersion,
      customNamespaces: {
        [NAMESPACE]: MessageType.JSON,
      },
      onShutdown: events.onShutdown,
    });
  });
}
