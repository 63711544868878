import { noop } from 'lodash-es';
import {
  getCDNTokenizationConfig,
  getPlaybackInfoWithAuthorizerV3,
  hasTokenization,
} from '@dtvgo/player-utils';
import { ErrorCode, Source } from '@dtvgo/error';

import { middleware } from '~/services/middleware';

import { login } from '~/store/credentials';
import { updateProfile } from '~/store/profile';

import { AppConfig } from '~/utils/appConfig';
import { errorFactory } from '~/utils/errorFactory';
import { Asset, Schedule } from '~/utils/metadata';

import { updateMediaMetadata } from './metadata';
import { MediaInformation } from './utils';

type LoadRequestData = Omit<framework.messages.LoadRequestData, 'media'> & {
  media: MediaInformation;
};

function parseLoadRequest(request: LoadRequestData) {
  const {
    assetId: contentId,
    forgerockTokensData: { idToken = undefined } = {},
    selectedProfile: { id: profileId = undefined } = {},
    isLive = false,
  } = request.media.customData || {};

  if (!idToken || !profileId || !contentId) {
    throw errorFactory.create('Some required params are missing', {
      source: Source.GENERAL,
      code: ErrorCode.MISSING_DATA,
      data: { idToken, profileId, contentId },
    });
  }

  return {
    idToken,
    profileId,
    contentId,
    isLive,
  };
}

export function getPlaybackInfo(
  appConfig: AppConfig,
  events?: {
    onPlaybackInfoAvailable?: (
      options: { isLive: boolean; contentId: string },
      playbackInfo: Awaited<ReturnType<typeof getPlaybackInfoWithAuthorizerV3>>,
      asset?: Asset | Schedule,
    ) => void;
    onAuthorizerError?: (error: Error) => void;
    onFinished?: () => void;
  },
) {
  return async (request: LoadRequestData) => {
    try {
      const { idToken, profileId, contentId, isLive } =
        parseLoadRequest(request);

      await login(idToken);
      await updateProfile(profileId);
      const [playbackInfoResult, assetResult] = await Promise.allSettled([
        getPlaybackInfoWithAuthorizerV3(
          middleware,
          { isLive, contentId },
          {
            onAuthorizerError: events?.onAuthorizerError || noop,
            refreshSecondsBefore: 90,
          },
        ),

        updateMediaMetadata({ contentId, isLive }, appConfig.metadata).catch(
          (error) => {
            console.error(
              '[cast>VrioOtt>updateMediaMetadata] update media metadata failed',
              error,
            );
            return undefined;
          },
        ),
      ]);

      if (playbackInfoResult.status === 'rejected') {
        throw playbackInfoResult.reason;
      }

      const { value: playbackInfo } = playbackInfoResult;
      const { provider, channelId, sources, clearRefreshTimeout } =
        playbackInfo;

      events?.onPlaybackInfoAvailable?.(
        { isLive, contentId },
        playbackInfo,
        assetResult.status === 'fulfilled' ? assetResult.value : undefined,
      );

      // TODO: Allow switch to other streams on failure
      const [source] =
        appConfig.lowLatency ? sources : (
          sources.filter(({ isLowLatency }) => !isLowLatency)
        );

      // This logic means we prefer dash, but fallback to hls if dash is not available (P+ content)
      const contentUrl = source?.dash || source?.hls;

      const onFinished = () => {
        clearRefreshTimeout();
        events?.onFinished?.();
      };

      if (!contentUrl) {
        onFinished();
        throw errorFactory.create('No playable source found', {
          source: Source.GENERAL,
          code: ErrorCode.MISSING_DATA,
        });
      }

      const { widevine, playready } = source.keySystemsConfig || {};
      const drmConfig = widevine || playready;

      let onRequest;
      let onResponseReceived;

      if (hasTokenization({ manifestUrl: contentUrl })) {
        ({ onRequest, onResponseReceived } = getCDNTokenizationConfig(
          appConfig.cdnTokenizationTokenType,
        ));
      }

      return {
        contentId,
        contentUrl,
        streamType:
          isLive ?
            cast.framework.messages.StreamType.LIVE
          : cast.framework.messages.StreamType.BUFFERED,
        drmConfig,
        bufferConfig: isLive ? appConfig.buffer.live : appConfig.buffer.vod,
        customData: { provider: provider || undefined, channelId },
        onRequest,
        onResponseReceived,
        onFinished,
      };
    } catch (error) {
      console.error('[cast>VrioOtt>getPlaybackInfo] error', error);
      const { ErrorData, ErrorReason, ErrorType } = cast.framework.messages;
      const errorData = new ErrorData(ErrorType.LOAD_FAILED);
      errorData.reason = ErrorReason.APP_ERROR;
      errorData.customData = { error: errorFactory.ensureVrioOttError(error) };
      return errorData;
    }
  };
}
