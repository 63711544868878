import { ContentType } from '@dtvgo/backend-service';

import { Asset, getImage, resizeImage } from '~/utils/metadata';

type MediaMetadata = framework.messages.MediaMetadata;

export function getMediaMetadata(asset: Asset) {
  let mediaMetadata;
  if (asset.type === ContentType.EPISODE) {
    mediaMetadata = new cast.framework.messages.TvShowMediaMetadata();
    mediaMetadata.episode = asset.episode?.number || undefined;
    mediaMetadata.season = asset.episode?.seasonNumber || undefined;
    mediaMetadata.seriesTitle = asset.episode?.showName || undefined;
  } else if (asset.type === ContentType.MOVIE) {
    mediaMetadata = new cast.framework.messages.MovieMediaMetadata();
  } else {
    mediaMetadata = new cast.framework.messages.GenericMediaMetadata();
  }

  mediaMetadata.title = asset.title || undefined;
  mediaMetadata.contentRating = asset.rating;
  mediaMetadata.releaseYear =
    'vod' in asset ? asset.vod?.releaseYear || undefined : undefined;

  if (asset.images) {
    const posterImage = getImage(asset.images, '16:9');
    (mediaMetadata as MediaMetadata).posterUrl =
      posterImage && resizeImage(posterImage, 1920).url;

    const portraitImage = getImage(asset.images, '2:3');
    if (portraitImage) {
      // TODO: Use the proper width according to the design and viewport width
      const portraitImageWidth = 300;
      mediaMetadata.images = [resizeImage(portraitImage, portraitImageWidth)];
    }
  }

  return mediaMetadata;
}
