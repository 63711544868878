import { Component, Ref, createEffect } from 'solid-js';
import NpawPlugin from 'npaw-plugin';
import { VrioOttError, VrioOttErrorFactory } from '@dtvgo/error';

import { AppInfo, ConfigureAdaptersOptions, integration } from '~/utils/npaw';
import { Asset } from '~/utils/metadata';

export interface NpawMonitoringRef {
  reportError: (
    error: VrioOttError,
    isFatal?: boolean,
    source?: string,
  ) => void;
}

interface NpawMonitoringProps {
  staticAccountCode: string;
  staticAppInfo: AppInfo;
  staticConfigureAdapters: (
    plugin: NpawPlugin,
    options: ConfigureAdaptersOptions,
  ) => void;
  staticErrorFactory: VrioOttErrorFactory;
  isConnected: boolean;
  toolboxToken: string | undefined;
  isLiveContent: boolean | undefined;
  asset: Asset | undefined;
  playSource: string | undefined;
  providerId: string | undefined;
  channelId: string | undefined;
  channelName?: string;
  vodPlayStartTime?: number;
  chromecastSender?: string;
  ref?: Ref<NpawMonitoringRef>;
}

const NpawMonitoring: Component<NpawMonitoringProps> = (props) => {
  const vrioOttIntegration = integration.init(
    props.staticAccountCode,
    props.staticConfigureAdapters,
    props.staticAppInfo,
    {
      isConnected: () => props.isConnected,
      errorFactory: props.staticErrorFactory,
    },
  );

  createEffect(() => {
    if (props.toolboxToken !== undefined)
      vrioOttIntegration.setToolboxToken(props.toolboxToken);
  });

  createEffect(() => {
    if (props.chromecastSender !== undefined)
      vrioOttIntegration.setChromecastSender(props.chromecastSender);
  });

  createEffect(() => {
    if (props.channelId !== undefined)
      vrioOttIntegration.setChannelId(props.channelId);
  });

  createEffect(() => {
    if (props.providerId !== undefined)
      vrioOttIntegration.setProviderId(props.providerId);
  });

  createEffect(() => {
    if (props.isLiveContent !== undefined)
      vrioOttIntegration.setIsLiveContent(props.isLiveContent);
  });

  createEffect(() => {
    if (props.asset) vrioOttIntegration.setAsset(props.asset);
  });

  createEffect(() => {
    if (props.channelName !== undefined)
      vrioOttIntegration.setChannelName(props.channelName);
  });

  createEffect(() => {
    if (props.playSource !== undefined)
      vrioOttIntegration.setPlaySource(props.playSource);
  });

  createEffect(() => {
    if (props.vodPlayStartTime !== undefined)
      vrioOttIntegration.setVodPlayStartTime(props.vodPlayStartTime);
  });

  const ref = {
    reportError: (
      error: VrioOttError,
      isFatal: boolean = false,
      source = 'NpawMonitoring-component',
    ) => {
      vrioOttIntegration.reportError(error, isFatal, source);
    },
  };

  createEffect(() => {
    // In theory at this point the ref should be always a function
    if (props.ref && typeof props.ref === 'function') props.ref(ref);
  });

  return undefined;
};

export default NpawMonitoring;
