import { Component, Ref } from 'solid-js';

import { configureAdapters } from '~/cast/npaw';

import { usePlayerData } from '~/contexts/PlayerDataContext';

import { idToken } from '~/store/credentials';
import { useChannel } from '~/store/tanstack-query';

import { isConnected } from '~/utils/functions';
import { errorFactory } from '~/utils/errorFactory';

import NpawMonitoring, { NpawMonitoringRef } from './NpawMonitoring';

interface NpawMonitoringContainerProps {
  ref?: Ref<NpawMonitoringRef>;
}

const NpawMonitoringContainer: Component<NpawMonitoringContainerProps> = (
  props,
) => {
  const playerData = usePlayerData();
  const { channel } = useChannel(() => playerData.media?.customData?.assetId);

  return (
    <NpawMonitoring
      staticAccountCode={import.meta.env.VITE_NPAW_ACCOUNT_CODE}
      staticAppInfo={{
        name: `${import.meta.env.VITE_THEME.toUpperCase()} Chromecast`,
        version: import.meta.env.VITE_APP_VERSION,
      }}
      staticConfigureAdapters={configureAdapters}
      staticErrorFactory={errorFactory}
      isConnected={isConnected()}
      toolboxToken={idToken()}
      isLiveContent={playerData.media?.customData?.isLive}
      asset={playerData.media?.customData?.asset}
      playSource={playerData.media?.customData?.playSource || ''}
      providerId={playerData.media?.customData?.provider}
      channelId={playerData.media?.customData?.channelId}
      channelName={
        playerData.media?.customData?.isLive ?
          channel()?.title || undefined
        : undefined
      }
      vodPlayStartTime={playerData.media?.customData?.vodPlayStartTime}
      chromecastSender={
        playerData.media?.customData &&
        `${playerData.media.customData.senderName} - ${playerData.media.customData.senderVersion}`
      }
      ref={props.ref}
    />
  );
};

export default NpawMonitoringContainer;
