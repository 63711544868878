import { MediaInformation } from './utils';

export type PlayerData<CustomDataType> = Omit<
  framework.ui.PlayerData,
  'media'
> & {
  media?: MediaInformation<CustomDataType>;
};

export function createPlayerData<CustomDataType>() {
  return new cast.framework.ui.PlayerData() as PlayerData<CustomDataType>;
}

export function bindPlayerData(playerData: framework.ui.PlayerData) {
  return new cast.framework.ui.PlayerDataBinder(playerData);
}
