import { createEffect } from 'solid-js';

export function createAssetChangeEffect(
  assetId: () => string | undefined,
  effect: () => void,
) {
  createEffect((prevAssetId?: string) => {
    const currentAssetId = assetId();
    if (currentAssetId !== prevAssetId) {
      effect();
    }

    return currentAssetId;
  });
}
