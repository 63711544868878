import { Component, createSignal, Show } from 'solid-js';

interface ChannelLogoProps {
  loadingData: boolean;
  channelLogo?: string;
  channelTitle?: string;
}

const ChannelLogo: Component<ChannelLogoProps> = (props) => {
  const [loading, setLoading] = createSignal(false);

  const skeletonClasses =
    'w-24 h-16 rounded-md bg-neutral-bg-disable/50 animate-pulse';

  return (
    <div class="size-40 flex items-center justify-center bg-neutral-bg-cross-black-secondary rounded-xl">
      <Show
        when={props.loadingData}
        fallback={
          <img
            src={props.channelLogo}
            alt={`${props.channelTitle} Logo`}
            class="w-24"
            classList={{
              [skeletonClasses]: loading(),
            }}
            onLoad={() => {
              setLoading(false);
            }}
            onError={() => {
              setLoading(false);
            }}
          />
        }
      >
        <span class={skeletonClasses} />
      </Show>
    </div>
  );
};

export default ChannelLogo;
