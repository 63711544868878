const Spinner = () => (
  <div class="w-20 h-20">
    <svg
      aria-hidden="true"
      viewBox="0 0 80 80"
      class="text-gray-200 animate-spin fill-main-bg-primary"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M61.5661 17.2522C55.6018 11.6046 47.6581 8.52339 39.4442 8.67197C31.2302 8.82055 23.4031 12.187 17.6471 18.0467C11.8912 23.9063 8.66634 31.7907 8.66634 40.003C8.66634 48.2154 11.8912 56.0998 17.6471 61.9594C23.4031 67.8191 31.2302 71.1856 39.4442 71.3341C41.6069 71.3733 43.3285 73.1582 43.2893 75.321C43.2502 77.4838 41.4652 79.2053 39.3025 79.1662C29.0365 78.9805 19.2536 74.773 12.0589 67.4488C4.86426 60.1246 0.833008 50.269 0.833008 40.003C0.833008 29.7371 4.86426 19.8815 12.0589 12.5573C19.2536 5.23309 29.0365 1.02561 39.3025 0.839913C49.5685 0.654215 59.4972 4.50511 66.9521 11.5643C74.407 18.6235 78.7923 28.3267 79.1638 38.586C79.1655 38.6332 79.1664 38.6805 79.1664 38.7277V41.2783C79.1664 43.4414 77.4128 45.1949 75.2497 45.1949C73.0866 45.1949 71.333 43.4414 71.333 41.2783V38.8044C71.0188 30.6228 67.5137 22.8841 61.5661 17.2522Z"
        fill="currentFill"
      />
    </svg>
    <span class="sr-only">Loading...</span>
  </div>
);

export default Spinner;
