import { Show, Component } from 'solid-js';
import { ContentType } from '@dtvgo/backend-service';

import { MESSAGES } from '~/utils/messages';

interface CategoryLabelProps {
  type: ContentType;
  isLive: boolean;
}

const ASSET_TYPE_MESSAGES = {
  [ContentType.CHANNEL]: MESSAGES.LIVE,
  [ContentType.MOVIE]: MESSAGES.MOVIE,
  [ContentType.SERIE]: MESSAGES.SERIE,
  [ContentType.EPISODE]: MESSAGES.SERIE,
  [ContentType.EVENT]: MESSAGES.SERIE,
  [ContentType.SPORT]: MESSAGES.SERIE,
};

const CategoryLabel: Component<CategoryLabelProps> = (props) => (
  <div class="flex items-center justify-center rounded-3xl bg-neutral-bg-cross-black-secondary px-3 h-8 w-fit">
    <Show when={props.isLive}>
      <span class="w-2 h-2 block rounded-full bg-complementary-bg-primary" />
    </Show>
    <span class="px-2 uppercase text-overline">
      {props.isLive ?
        ASSET_TYPE_MESSAGES.LIVE
      : ASSET_TYPE_MESSAGES[props.type]}
    </span>
  </div>
);

export default CategoryLabel;
