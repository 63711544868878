import { createToolboxService } from '@dtvgo/toolbox-service';

import { deviceId } from '~/store/deviceId';
import { CLIENT_HEADERS_OPTIONS } from '~/utils/clientHeadersOptions';
import { TOOLBOX_REGION } from '~/utils/constants';
import { errorFactory } from '~/utils/errorFactory';
import { isConnected } from '~/utils/functions';

export const toolbox = createToolboxService({
  host: import.meta.env.VITE_TOOLBOX_HOST,
  auth: {
    username: import.meta.env.VITE_TOOLBOX_USERNAME,
    password: import.meta.env.VITE_TOOLBOX_PASSWORD,
  },
  deviceId: deviceId(),
  clientHeadersOptions: CLIENT_HEADERS_OPTIONS,
  region: TOOLBOX_REGION,
  errorFactory,
  isConnected,
});
