import { AppConfig } from '~/utils/appConfig';

import { onLiveMediaFinished, updateLiveMediaMetadata } from './live';
import { updateVodMediaMetadata } from './vod';

export async function updateMediaMetadata(
  {
    isLive,
    contentId,
  }: {
    isLive: boolean;
    contentId: string;
  },
  config: AppConfig['metadata'],
) {
  const player =
    cast.framework.CastReceiverContext.getInstance().getPlayerManager();
  const onFinished = () => {
    player.removeEventListener(
      cast.framework.events.EventType.MEDIA_FINISHED,
      onFinished,
    );
    if (isLive) {
      onLiveMediaFinished();
    }
  };

  player.addEventListener(
    cast.framework.events.EventType.MEDIA_FINISHED,
    onFinished,
  );

  return isLive ?
      updateLiveMediaMetadata(contentId, config.live)
    : updateVodMediaMetadata(contentId);
}
