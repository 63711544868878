import { middleware } from '~/services/middleware';

import { profile, setProfile } from './data';

export async function updateProfile(profileId: string) {
  if (profile()?.id === profileId) return;

  const { data } = await middleware.customer.v1.getProfile(profileId);
  setProfile(data);
}
