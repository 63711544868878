import {
  ClientHeadersInterceptorParams,
  AppEnvironment,
} from '@dtvgo/axios-interceptor-client-headers';

const EnvironmentMapping = {
  local: AppEnvironment.DEV,
  dev: AppEnvironment.DEV,
  alpha: AppEnvironment.ALPHA,
  beta: AppEnvironment.BETA,
  prod: AppEnvironment.PRODUCTION,
};

export const CLIENT_HEADERS_OPTIONS: ClientHeadersInterceptorParams = {
  clientId: 'chromecast',
  appName: import.meta.env.VITE_THEME,
  appVersion: import.meta.env.VITE_APP_VERSION,
  environment: EnvironmentMapping[import.meta.env.VITE_ENVIRONMENT],
};
