import {
  PlayerRequestType,
  PlayerRequestInterceptor,
  PlayerResponseInterceptor,
} from '@dtvgo/player-common-utils';
import { normalizeError } from '@dtvgo/shaka-player-utils';

export type MediaInformation<CustomDataType> = Omit<
  framework.messages.MediaInformation,
  'customData'
> & {
  customData?: CustomDataType;
};

export function onRequestProxy(
  requestType: PlayerRequestType,
  onRequest: PlayerRequestInterceptor,
  method: string,
) {
  return async (request: framework.NetworkRequestInfo) => {
    const { uris, headers, body, allowCrossSiteCredentials } =
      (await onRequest(requestType, {
        uris: request.url ? [request.url] : [],
        headers: request.headers as { [key: string]: string },
        body: request.content,
        allowCrossSiteCredentials: request.withCredentials,
        method,
      })) || {};

    request.url = uris?.[0] ?? request.url;
    request.headers = {
      ...(request.headers as { [key: string]: string }),
      ...headers,
    };
    request.content = (body as Uint8Array | undefined) ?? request.content;
    request.withCredentials =
      allowCrossSiteCredentials ?? request.withCredentials;

    return request;
  };
}

export function onResponseReceivedProxy(
  requestType: PlayerRequestType,
  onResponseReceived: PlayerResponseInterceptor,
) {
  return async (
    data: unknown,
    response: { headers: { [key: string]: string } },
  ) => {
    const { data: newData } =
      (await onResponseReceived(requestType, {
        data,
        headers: response.headers,
        uri: '',
      })) || {};
    return newData || data;
  };
}

export const PlayerState = cast.framework.ui.State;
export type PlayerStateType = framework.ui.State;

interface ShakaErrorData {
  shakaErrorSeverity: shaka.util.Error.Severity;
  shakaErrorCategory: shaka.util.Error.Category;
  shakaErrorCode: shaka.util.Error.Code;
  shakaErrorData: unknown[];
  shakaErrorStack: string;
}

export function isShakaErrorData(error: unknown): error is ShakaErrorData {
  return (
    typeof error === 'object' &&
    error !== null &&
    'shakaErrorCode' in error &&
    error.shakaErrorCode !== undefined
  );
}

export function toShakaErrorLike(error: ShakaErrorData) {
  return {
    severity: error.shakaErrorSeverity,
    category: error.shakaErrorCategory,
    code: error.shakaErrorCode,
    data: error.shakaErrorData,
    stack: error.shakaErrorStack,
  };
}

interface WithErrorInCustomData {
  customData: {
    error: unknown;
  };
}

export function hasErrorInCustomData(
  error: unknown,
): error is WithErrorInCustomData {
  return (
    typeof error === 'object' &&
    error !== null &&
    'customData' in error &&
    typeof error.customData === 'object' &&
    error.customData !== null &&
    'error' in error.customData &&
    !!error.customData.error
  );
}

export function parseCastError(error: unknown) {
  if (isShakaErrorData(error)) {
    return normalizeError(toShakaErrorLike(error));
  }
  if (hasErrorInCustomData(error)) {
    return error.customData.error;
  }
  return error;
}

export function addErrorListener(listener: (error: unknown) => void) {
  const handleError = (event: framework.events.ErrorEvent) => {
    if (event.error) {
      listener(parseCastError(event.error));
    }
  };

  cast.framework.CastReceiverContext.getInstance()
    .getPlayerManager()
    .addEventListener(cast.framework.events.EventType.ERROR, handleError);

  return () => {
    cast.framework.CastReceiverContext.getInstance()
      .getPlayerManager()
      .removeEventListener(cast.framework.events.EventType.ERROR, handleError);
  };
}

export function addEndedListener(listener: () => void) {
  cast.framework.CastReceiverContext.getInstance()
    .getPlayerManager()
    .addEventListener(cast.framework.events.EventType.ENDED, listener);
}

export function removeEndedListener(listener: () => void) {
  cast.framework.CastReceiverContext.getInstance()
    .getPlayerManager()
    .removeEventListener(cast.framework.events.EventType.ENDED, listener);
}

export function addMediaFinishedListener(listener: () => void) {
  cast.framework.CastReceiverContext.getInstance()
    .getPlayerManager()
    .addEventListener(cast.framework.events.EventType.MEDIA_FINISHED, listener);
}

export function removeMediaFinishedListener(listener: () => void) {
  cast.framework.CastReceiverContext.getInstance()
    .getPlayerManager()
    .removeEventListener(
      cast.framework.events.EventType.MEDIA_FINISHED,
      listener,
    );
}

export function load(loadRequest: framework.messages.LoadRequestData) {
  cast.framework.CastReceiverContext.getInstance()
    .getPlayerManager()
    .load(loadRequest)
    .catch((error) => {
      console.error('[cast>lib>utils>loadMedia] load failed', error);
    });
}

export function stopPlayback() {
  cast.framework.CastReceiverContext.getInstance().getPlayerManager().stop();
}

export function broadcastCustomMessage(
  namespace: string,
  data: { type: string; [key: string]: unknown },
) {
  cast.framework.CastReceiverContext.getInstance().sendCustomMessage(
    namespace,
    undefined,
    data,
  );
}
