import { createSignal, onCleanup, untrack, createEffect } from 'solid-js';

import { addEndedListener, load, removeEndedListener } from '~/cast/lib';
import { getLoadRequest } from '~/cast/VrioOtt';

import {
  Asset,
  findNextEpisodeId,
  isEpisodeAndHasInfo,
} from '~/utils/metadata';

export function useAutoplayNextEpisode() {
  const [episodeInfo, setEpisodeInfo] = createSignal<
    { showId: string; seasonNumber: number; episodeNumber: number } | undefined
  >();

  const handleEnded = () => {
    const currentEpisodeInfo = untrack(episodeInfo);
    if (currentEpisodeInfo) {
      findNextEpisodeId(currentEpisodeInfo)
        .then((nextEpisodeId) => {
          if (!nextEpisodeId) return undefined;

          load(getLoadRequest(nextEpisodeId, false));
          return undefined;
        })
        .catch((error) => {
          // Sould never enter here, in theory.
          console.error(
            '[App>addEndedListener] findNextEpisodeId failed',
            error,
          );
        });
    }
  };

  createEffect(() => {
    addEndedListener(handleEnded);
    onCleanup(() => {
      removeEndedListener(handleEnded);
    });
  });

  return {
    onAssetReceived: (asset: Asset, isLive: boolean) => {
      if (!isLive && isEpisodeAndHasInfo(asset)) {
        setEpisodeInfo({
          showId: asset.episode.showId,
          seasonNumber: asset.episode.seasonNumber,
          episodeNumber: asset.episode.number,
        });
      }
    },
    onFinished: () => {
      setEpisodeInfo(undefined);
    },
  };
}
