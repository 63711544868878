import { channelsQuery, queryClient } from '~/store/tanstack-query';
import { AppConfig } from '~/utils/appConfig';
import {
  findScheduleAtTime,
  getChannelSchedulesWithRetries,
  getImage,
  resizeImage,
  Schedule,
} from '~/utils/metadata';
import { combineLiveSections } from '~/utils/functions';
import { WithRequired } from '~/utils/types';

import { getMediaMetadata } from './utils';
import { LiveMediaCustomData } from '../utils';

type MediaMetadata = framework.messages.MediaMetadata;
type LiveMediaMetadata = WithRequired<
  MediaMetadata,
  'sectionStartAbsoluteTime' | 'sectionDuration'
>;
type EnsureLiveSchedule = WithRequired<Schedule, 'live'>;

let updateSchedulesMetadataTimeout: ReturnType<typeof setTimeout> | undefined;
let onTimeUpdate: (() => void) | undefined;

export async function updateLiveMediaMetadata(
  channelId: string,
  config: AppConfig['metadata']['live'],
) {
  const player =
    cast.framework.CastReceiverContext.getInstance().getPlayerManager();

  onTimeUpdate = () => {
    updatePlayingSchedule();
  };

  player.addEventListener(
    cast.framework.events.EventType.TIME_UPDATE,
    onTimeUpdate,
  );

  const [, schedulesResult] = await Promise.allSettled([
    updateChannelMetadata(channelId),
    updateSchedulesMetadata(channelId, config),
  ]);

  return schedulesResult.status === 'fulfilled' ?
      schedulesResult.value
    : undefined;
}

export function onLiveMediaFinished() {
  const player =
    cast.framework.CastReceiverContext.getInstance().getPlayerManager();

  if (onTimeUpdate) {
    player.removeEventListener(
      cast.framework.events.EventType.TIME_UPDATE,
      onTimeUpdate,
    );

    onTimeUpdate = undefined;
  }

  if (updateSchedulesMetadataTimeout) {
    clearTimeout(updateSchedulesMetadataTimeout);
    updateSchedulesMetadataTimeout = undefined;
  }
}

function getContainerMetadata() {
  const queueManager = cast.framework.CastReceiverContext.getInstance()
    .getPlayerManager()
    .getQueueManager()!;
  let containerMetadata = queueManager.getContainerMetadata();
  if (!containerMetadata) {
    containerMetadata = new cast.framework.messages.ContainerMetadata();
    queueManager.setContainerMetadata(containerMetadata);
  }

  return containerMetadata;
}

async function updateChannelMetadata(channelId: string) {
  const channels = await queryClient.fetchQuery(channelsQuery());

  const channelMetadata = channels.find(
    (channel) => channel.channelId === channelId,
  );
  if (channelMetadata) {
    const containerMetadata = getContainerMetadata();

    containerMetadata.title = channelMetadata.title || undefined;
    const channelImage =
      channelMetadata.images && getImage(channelMetadata.images, '4:3');

    // TODO: Use proper width according to the design and viewport width
    const channelImageWidth = 300;
    containerMetadata.containerImages =
      (channelImage && [resizeImage(channelImage, channelImageWidth)]) ||
      undefined;
  }
  return channelMetadata;
}

async function updateSchedulesMetadata(
  channelId: string,
  config: AppConfig['metadata']['live'],
) {
  if (updateSchedulesMetadataTimeout) {
    clearTimeout(updateSchedulesMetadataTimeout);
    updateSchedulesMetadataTimeout = undefined;
  }

  const { schedules } = await getChannelSchedulesWithRetries(
    channelId,
    config.secondsToRecheckWhenNotFound,
    config.maxRetriesWhenNotFound,
  );

  const filteredSchedules = schedules.filter(
    ({ live }) => !!live,
  ) as WithRequired<Schedule, 'live'>[];

  const player =
    cast.framework.CastReceiverContext.getInstance().getPlayerManager();

  const absoluteLiveEdgeTimeSeconds =
    player.getAbsoluteTimeForMediaTime(
      (player.getLiveSeekableRange() as { end: number } | null)?.end || 0,
    ) ?? Date.now() / 1000;

  const mediaInformation =
    player.getMediaInformation() ??
    new cast.framework.messages.MediaInformation();

  if (!mediaInformation.customData) {
    mediaInformation.customData = {};
  }

  const customData = mediaInformation.customData as LiveMediaCustomData;
  customData.isLive = true;

  customData.schedules = combineLiveSections(
    customData.schedules || [],
    filteredSchedules,
    (schedule) => ({
      start: new Date(schedule.live!.startTime).getTime(),
      end: new Date(schedule.live!.endTime).getTime(),
    }),
  );

  const playingSchedule = updatePlayingSchedule();

  player.setMediaInformation(mediaInformation);

  const scheduleAtLiveEdge = findScheduleAtTime(
    customData.schedules,
    absoluteLiveEdgeTimeSeconds,
  );

  if (scheduleAtLiveEdge) {
    const timeToRecheckSchedules =
      new Date(scheduleAtLiveEdge.live!.endTime).getTime() -
      absoluteLiveEdgeTimeSeconds * 1000;

    updateSchedulesMetadataTimeout = setTimeout(() => {
      updateSchedulesMetadata(channelId, config).catch((error) => {
        console.error(
          '[cast>VrioOtt>updateSchedulesMetadata>updateSchedulesMetadataTimeout] updateSchedulesMetadata failed',
          error,
        );
      });
      updateSchedulesMetadataTimeout = undefined;
    }, timeToRecheckSchedules);
  }

  const containerMetadata = getContainerMetadata();
  containerMetadata.sections = combineLiveSections(
    (containerMetadata.sections as LiveMediaMetadata[] | undefined) || [],
    filteredSchedules.map(getLiveMediaMetadata),
    (mediaMetadata) => {
      const start = mediaMetadata.sectionStartAbsoluteTime;
      return {
        start,
        end: start + mediaMetadata.sectionDuration,
      };
    },
  );

  return playingSchedule;
}

function updatePlayingSchedule() {
  const player =
    cast.framework.CastReceiverContext.getInstance().getPlayerManager();
  const customData = player.getMediaInformation()?.customData as
    | { schedules?: EnsureLiveSchedule[]; asset?: Schedule }
    | undefined;
  if (!customData?.schedules) return undefined;

  const absolutePlayingTimeSeconds =
    player.getAbsoluteTimeForMediaTime(player.getCurrentTimeSec()) ??
    Date.now() / 1000;

  const playingSchedule = findScheduleAtTime(
    customData.schedules,
    absolutePlayingTimeSeconds,
  );

  customData.asset = playingSchedule;
  return playingSchedule;
}

function getLiveMediaMetadata(schedule: EnsureLiveSchedule) {
  const mediaMetadata = getMediaMetadata(schedule) as MediaMetadata;

  const startTime = new Date(schedule.live.startTime).getTime();
  const endTime = new Date(schedule.live.endTime).getTime();
  const durationMs = endTime - startTime;

  mediaMetadata.sectionDuration = Math.floor(durationMs / 1000);
  mediaMetadata.sectionStartAbsoluteTime = Math.floor(startTime / 1000);

  return mediaMetadata as LiveMediaMetadata;
}
