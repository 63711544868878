import { createEffect } from 'solid-js';
import { Location } from '@dtvgo/error';

import { setLocation } from '~/utils/errorFactory';

import { CastUIState } from './useUiState';

export function createUpdateErrorFactoryLocationEffect(
  uiState: () => CastUIState,
) {
  createEffect(() => {
    const currentUiState = uiState();
    if (
      [CastUIState.IDLE, CastUIState.LAUNCHING, CastUIState.ERROR].includes(
        currentUiState,
      )
    ) {
      setLocation(Location.SPLASH_SCREEN);
    } else if (currentUiState === CastUIState.LOADING) {
      setLocation(Location.PLAYER_BEFORE_VIDEO_START);
    } else {
      setLocation(Location.PLAYER_PLAYBACK);
    }
  });
}
