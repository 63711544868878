import { Credentials } from '@dtvgo/credentials-renew';

const LOCAL_STORAGE_CREDENTIALS_KEY = 'credentials';
export function loadCredentials() {
  try {
    return (
      (JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_CREDENTIALS_KEY) || 'null',
      ) as Credentials | null) || undefined
    );
  } catch {
    return undefined;
  }
}

export function saveCredentials(credentials: Credentials | undefined) {
  if (!credentials) {
    localStorage.removeItem(LOCAL_STORAGE_CREDENTIALS_KEY);
    return;
  }
  localStorage.setItem(
    LOCAL_STORAGE_CREDENTIALS_KEY,
    JSON.stringify(credentials),
  );
}
