import { createContext, useContext } from 'solid-js';

import { PlayerData } from '~/cast/lib';
import { MediaCustomData } from '~/cast/VrioOtt';

const PlayerDataContext = createContext<PlayerData<MediaCustomData>>();

export const usePlayerData = () => {
  const context = useContext(PlayerDataContext);
  if (!context) {
    throw new Error('usePlayerData must be used within a PlayerDataProvider');
  }
  return context;
};

export { PlayerDataContext };
