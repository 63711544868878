// Numbers
export const [DD_SAMPLE_RATE, DD_REPLAY_SAMPLE_RATE] = [
  import.meta.env.VITE_DATADOG_SAMPLE_RATE,
  import.meta.env.VITE_DATADOG_REPLAY_SAMPLE_RATE,
].map((value) => (value ? parseNumber(value) : undefined));

// Booleans
export const [IS_DATADOG_ACTIVE] = [import.meta.env.VITE_IS_DATADOG_ACTIVE].map(
  (value) => (value ? parseBoolean(value) : false),
);

export function parseBoolean(value: string) {
  return value === 'true';
}

export function parseNumber(value: string) {
  const parsedValue = Number.parseFloat(value);

  if (Number.isNaN(parsedValue)) return undefined;
  return parsedValue;
}
