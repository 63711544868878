import { Component, JSX } from 'solid-js';

import logo from '~/assets/theme/logo.svg';
import loader from '~/assets/theme/loader.svg';

interface ConnectionLayoutProps {
  children: JSX.Element;
}

const ConnectionLayout: Component<ConnectionLayoutProps> = (props) => (
  <div class="flex w-screen h-screen items-center justify-between relative overflow-hidden p-40 pt-60">
    <div class="flex flex-col h-full relative gap-16">
      <div class="w-fit">
        <img
          src={logo}
          alt={`${import.meta.env.VITE_APP_NAME} logo`}
          class="h-36"
        />
      </div>
      {props.children}
    </div>
    <img src={loader} alt="Loader" class="h-[120%] absolute right-0 -top-20" />
  </div>
);

export default ConnectionLayout;
