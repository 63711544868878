import { Show } from 'solid-js';

import { PlayerState } from '~/cast/lib';

import { usePlayerData } from '~/contexts/PlayerDataContext';

import AssetMetadata from '~/components/AssetMetadata';
import ProgressBar from '~/components/ProgressBar';

import ChannelLogoContainer from '~/containers/ChannelLogo';

import { MESSAGES } from '~/utils/messages';

import iconPlay from '~/assets/icon_play.svg';
import iconPause from '~/assets/icon_pause.svg';

const ControlsScreen = () => {
  const playerData = usePlayerData();
  const isLive = () => playerData?.media?.customData?.isLive ?? false;

  const isPaused = () => playerData.state === PlayerState.PAUSED;
  const icon = () => (isPaused() ? iconPause : iconPlay);
  const iconAlt = () => (isPaused() ? 'Pause icon' : 'Play icon');

  const asset = () => playerData.media?.customData?.asset;

  const liveSeekableRange = () =>
    playerData.liveSeekableRange as framework.messages.LiveSeekableRange & {
      start: number | undefined;
      end: number | undefined;
    };

  return (
    <div
      class="flex flex-col w-screen h-screen relative bg-neutral-bg-primary/85 p-40 items-center justify-end transition-opacity"
      classList={{ 'opacity-0 delay-[2000ms] duration-1000': !isPaused() }}
    >
      <div class="absolute flex w-full h-full top-0 left-0">
        <img src={icon()} alt={iconAlt()} class="h-20 m-auto" />
      </div>
      <p class="absolute top-20 text-center text-heading-xs m-auto text-neutral-text-secondary">
        {MESSAGES.PAUSE_HINT}
      </p>
      <div class="flex flex-col justify-end h-full gap-12 w-full z-10">
        <Show when={isLive()}>
          <ChannelLogoContainer
            channelId={playerData.media!.customData!.assetId}
          />
        </Show>
        <Show when={asset()}>
          <AssetMetadata isLive={isLive()} asset={asset()!} />
        </Show>
        <div class="mt-4">
          <ProgressBar
            progress={
              isLive() ?
                playerData.currentTime -
                (playerData.sectionStartTimeInMedia ??
                  liveSeekableRange()?.start ??
                  0)
              : playerData.currentTime
            }
            duration={
              isLive() ?
                playerData.sectionDuration ?? liveSeekableRange()?.end ?? 0
              : playerData.duration
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ControlsScreen;
