import { Region } from '@dtvgo/toolbox-service';

export const IS_BRAZIL = import.meta.env.VITE_THEME === 'skymais';
export const LANGUAGE = import.meta.env.VITE_THEME === 'skymais' ? 'pt' : 'es';

export const TOOLBOX_REGION =
  import.meta.env.VITE_THEME === 'skymais' ? Region.BR : Region.SSLA;

export const NAMESPACE = 'urn:x-cast:com.directvgo.cast.chromecast';

export enum CustomMessageType {
  ASSET_LOAD = 'assetLoad',
  ERROR = 'error_cast',
  CONNECTED = 'type_connection',
}
