import { createMemo } from 'solid-js';

import ChannelLogo from '~/components/ChannelLogo';

import { useChannel } from '~/store/tanstack-query';

import { getImage, resizeImage } from '~/utils/metadata';

interface ChannelLogoContainerProps {
  channelId: string;
}

export default function ChannelLogoContainer(props: ChannelLogoContainerProps) {
  const { channel, isLoading } = useChannel(() => props.channelId);

  const channelTitle = () => channel()?.title || undefined;
  const channelLogo = createMemo(() => {
    const channelMetadata = channel();
    const channelImage =
      (channelMetadata?.images && getImage(channelMetadata.images, '4:3')) ??
      undefined;
    return channelImage && resizeImage(channelImage, 300).url;
  });

  return (
    <ChannelLogo
      channelTitle={channelTitle()}
      channelLogo={channelLogo()}
      loadingData={isLoading()}
    />
  );
}
