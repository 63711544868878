import NpawPlugin from 'npaw-plugin';

import { getNpawAdapterCAF } from './NpawAdapterCAF';
import { CAFAdapterOptions } from './utils';

export function configureAdapters(
  plugin: NpawPlugin,
  options: CAFAdapterOptions,
) {
  plugin.registerAdapterFromClass(
    cast.framework.CastReceiverContext.getInstance().getPlayerManager(),
    getNpawAdapterCAF(options),
  );
}
