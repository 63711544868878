import { createEffect, untrack } from 'solid-js';
import { PlaybackHeartbeatController } from '@dtvgo/player-utils';

import { middleware } from '~/services/middleware';
import { deviceId } from '~/store/deviceId';
import { Asset, getHeartbeatContent, Schedule } from '~/utils/metadata';

import { createAssetChangeEffect } from './createAssetChangeEffect';

export function createHeartbeatController(
  getConfig: () =>
    | { heartbeatTimeInSeconds: number; useStreamingV4: boolean }
    | undefined,
  {
    streamedTime,
    progress,
    duration,
    isLive,
    asset,
  }: {
    streamedTime: () => number;
    progress: () => number;
    duration: () => number;
    isLive: () => boolean;
    asset: () => Asset | Schedule | undefined;
  },
) {
  let heartbeatController: PlaybackHeartbeatController | undefined;

  createEffect(() => {
    if (heartbeatController) return;

    const config = getConfig();
    if (!config) return;

    heartbeatController = new PlaybackHeartbeatController({
      backendService: middleware,
      heartbeatTimeInSeconds: config.heartbeatTimeInSeconds,
      deviceId: deviceId(),
      getStreamedTime: streamedTime,
      useStreamingV4: config.useStreamingV4,
    });
  });

  createEffect(() => {
    if (heartbeatController) {
      heartbeatController.updateDuration(duration());
    }
  });

  createEffect(() => {
    if (heartbeatController) {
      heartbeatController.updateProgress(progress());
    }
  });

  const assetId = () => asset()?.vrioAssetId;

  createAssetChangeEffect(assetId, () => {
    if (heartbeatController) {
      const currentAsset = untrack(asset);
      heartbeatController.setContent({
        isLive: untrack(isLive),
        ...(currentAsset && getHeartbeatContent(currentAsset)),
      });
    }
  });

  return {
    startHeartbeat: (
      params: Parameters<PlaybackHeartbeatController['start']>[0],
    ) => {
      if (heartbeatController) {
        heartbeatController.start(params).catch((startHeartbeatError) => {
          console.error(
            '[App>startHeartbeat] heartbeat start failed',
            startHeartbeatError,
          );
        });
      }
    },
    stopHeartbeat: () => {
      if (heartbeatController) {
        heartbeatController.stop().catch((stopHeartbeatError) => {
          console.error(
            '[App>stopHeartbeat] heartbeat stop failed',
            stopHeartbeatError,
          );
        });
      }
    },
  };
}
