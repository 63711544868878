import { Component, Show, createMemo } from 'solid-js';
import { ContentType } from '@dtvgo/backend-service';

import CategoryLabel from '~/components/CategoryLabel';
import HorizontalList from '~/components/HorizontalList';
import Rating from '~/components/Rating';

import { formatSecondsInText } from '~/utils/functions';
import { MESSAGES } from '~/utils/messages';
import { Asset, Schedule } from '~/utils/metadata';

interface AssetMetadataProps {
  isLive: boolean;
  asset: Asset | Schedule;
}

const AssetMetadata: Component<AssetMetadataProps> = (props) => {
  const assetDuration = createMemo(() => {
    if (props.isLive && 'live' in props.asset && props.asset.live) {
      return Math.floor(
        (new Date(props.asset.live?.endTime).getTime() -
          new Date(props.asset.live?.startTime).getTime()) /
          1000,
      );
    }

    const duration = props.asset.vod?.duration ?? props.asset.duration;

    if (typeof duration !== 'number') {
      return undefined;
    }

    return formatSecondsInText(duration);
  });

  const title = () => {
    if (props.asset.type === ContentType.EPISODE) {
      return props.asset.episode?.showName || props.asset.title;
    }

    return props.asset.title;
  };

  return (
    <div class="flex flex-col gap-4">
      <CategoryLabel type={props.asset?.type} isLive={props.isLive} />
      <h1 class="text-heading-3xl">{title()}</h1>
      <div class="flex items-center gap-3 text-body-m text-neutral-text-secondary">
        <Show when={!!props.asset.rating}>
          <Rating rating={props.asset.rating!} />
        </Show>
        <HorizontalList
          items={[
            props.asset.vod?.releaseYear,
            assetDuration(),
            ...(props.asset.type === ContentType.EPISODE ?
              [
                `${MESSAGES.SEASON_FIRST_CHARACTER}${props.asset.episode?.seasonNumber} ${MESSAGES.EPISODE_FIRST_CHARACTER}${props.asset.episode?.number}`,
                props.asset.title,
              ]
            : [props.asset.genres?.join(', ')]),
          ].filter(Boolean)}
        />
      </div>
    </div>
  );
};

export default AssetMetadata;
