import { Component } from 'solid-js';

import { formatSecondsInTime } from '~/utils/functions';

interface ProgressProps {
  progress: number;
  duration: number;
}

const ProgressBar: Component<ProgressProps> = (props) => {
  const percentage = () => props.progress / props.duration;

  return (
    <div class="flex w-full items-center gap-6">
      <span class="text-body-m w-28 text-left">
        {formatSecondsInTime(props.progress)}
      </span>
      <span class="flex bg-neutral-bg-cross-gray overflow-hidden w-full h-3">
        <span
          class="block h-full w-full bg-neutral-bg-hover-disable origin-left"
          style={{
            transform: `scaleX(${percentage()})`,
          }}
        />
      </span>
      <span class="text-body-m w-28 text-right">
        {formatSecondsInTime(props.duration)}
      </span>
    </div>
  );
};

export default ProgressBar;
