import { createEffect, createMemo, createSignal, createRoot } from 'solid-js';
import {
  getMSOProviderFormatted,
  getUserTypeByBusinessUnit,
} from '@dtvgo/utils';

import { decodeToolboxToken } from '~/utils/toolbox';

import { loadCredentials, saveCredentials } from './utils';

const credentialsRoot = createRoot(() => {
  const [credentials, setCredentials] = createSignal(loadCredentials());

  createEffect(() => {
    const currentCredentials = credentials();
    saveCredentials(currentCredentials);
  });

  const idToken = () => credentials()?.idToken;

  const decodedIdToken = createMemo(() => {
    const idTokenValue = idToken();
    if (!idTokenValue) return undefined;

    return decodeToolboxToken(idTokenValue);
  });

  const userId = () => decodedIdToken()?.vrioCustomerId;

  const businessUnit = () => decodedIdToken()?.businessUnit;

  const msoProvider = () => decodedIdToken()?.msoProvider;

  const userFirstName = () => decodedIdToken()?.givenName;

  const userLastName = () => decodedIdToken()?.familyName;

  const userEmail = () => decodedIdToken()?.sub;

  const userType = createMemo(() => {
    const businessUnitValue = businessUnit();
    if (!businessUnitValue) return undefined;

    return getUserTypeByBusinessUnit(businessUnitValue);
  });

  const formattedMsoProvider = createMemo(() => {
    const businessUnitValue = businessUnit();
    if (!businessUnitValue) return undefined;

    return getMSOProviderFormatted({
      msoProvider: msoProvider(),
      businessUnit: businessUnitValue,
    });
  });

  const datadogUser = () =>
    userId() === undefined ? undefined : (
      {
        id: userId(),
        name: `${userFirstName() || ''} ${userLastName() || ''}`,
        email: userEmail(),
        loginType: userType(),
      }
    );

  return {
    credentials,
    setCredentials,
    idToken,
    decodedIdToken,
    userId,
    businessUnit,
    msoProvider,
    userType,
    formattedMsoProvider,
    datadogUser,
  };
});

export const {
  credentials,
  setCredentials,
  idToken,
  decodedIdToken,
  userId,
  businessUnit,
  msoProvider,
  userType,
  formattedMsoProvider,
  datadogUser,
} = credentialsRoot;
