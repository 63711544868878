import { Component, createMemo, For } from 'solid-js';

import { VrioOttError } from '@dtvgo/error';

import { usePlayerData } from '~/contexts/PlayerDataContext';
import { userId } from '~/store/credentials';
import { getErrorTexts, MESSAGES } from '~/utils/messages';

import logo from '~/assets/theme/logo.svg';

interface ErrorScreenProps {
  error: VrioOttError;
}

const [, OS_VERSION] = navigator.userAgent.match(/CrKey\/([\d.]+)/) || [];

const ErrorScreen: Component<ErrorScreenProps> = (props) => {
  const playerData = usePlayerData();

  const errorTexts = createMemo(() => getErrorTexts(props.error));

  const informationContent = () =>
    [
      playerData.media?.customData?.asset?.vmsId,
      userId(),
      new Date(props.error.timestamp).toISOString(),
      `${import.meta.env.VITE_APP_VERSION} | Chrome OS ${OS_VERSION || ''}`,
    ].filter(Boolean);

  return (
    <div class="flex w-screen h-screen bg-neutral-bg-primary px-96 relative">
      <div class="flex flex-col w-full align-items-center justify-content-center gap-16 my-auto">
        <img
          src={logo}
          alt={`${import.meta.env.VITE_APP_TITLE} logo`}
          class="h-36"
        />
        <div class="flex flex-col gap-12 w-full">
          <h1 class="text-neutral-text-primary text-heading-2xl text-center line-clamp-2">
            {errorTexts().title}
          </h1>
          <p class="text-neutral-text-secondary text-body-l text-center">
            {errorTexts().message}
          </p>
          <p class="text-neutral-text-primary text-heading-s text-center">
            {MESSAGES.ERROR_SCREEN.CODE}: {props.error.userCode}
          </p>
        </div>
      </div>
      <div class="absolute bottom-20 right-24 text-neutral-text-secondary text-body-s text-right">
        <For each={informationContent()}>{(item) => <p>{item}</p>}</For>
      </div>
    </div>
  );
};

export default ErrorScreen;
