import { v4 as uuid } from 'uuid';
import { cacheFunction } from '@dtvgo/utils';

export const deviceId = cacheFunction(() => {
  const localStorageDeviceId = localStorage.getItem('deviceId');
  if (localStorageDeviceId) return localStorageDeviceId;

  const generatedDeviceId = uuid();
  localStorage.setItem('deviceId', generatedDeviceId);
  return generatedDeviceId;
});
